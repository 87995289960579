<template>
  <v-navigation-drawer
    v-model="getShowMobileMenu"
    temporary
    width="360"
    :color="scssVariables.jColorBgMain"
    :scrim="scssVariables.jColorModalShadow"
    class="j-mobile-menu"
  >
    <v-list-item
      :class="[
        'pt-2',
        'j-mobile-menu__item',
        'px-8',
        {
          'pb-6': !getUserData.loggedIn,
          'pb-9': getUserData.loggedIn,
        },
      ]"
    >
      <general-logged-user-panel v-if="getUserData.loggedIn" />
      <general-login-buttons-block v-else />
    </v-list-item>
    <v-divider />
    <j-mobile-menu-parts-items-list :menu-items-list="MOBILE_NAV_MENU_ITEMS" />
    <v-divider />
    <j-mobile-menu-parts-items-list :menu-items-list="MOBILE_NAV_INFO_ITEMS" />
    <v-list-item class="pb-6 pt-2 px-8">
      <v-select
        v-model="$i18n.locale"
        :items="localesConvertedToArray"
        item-title="localeNameMobile"
        item-value="localeValue"
        variant="solo"
        :bg-color="scssVariables.jColorBtnRegular"
        class="font-weight-medium"
        @update:model-value="changeAppLocale"
      />
    </v-list-item>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useLayoutStore } from '~/stores/layout';
import { useAuthStore } from '~/stores/auth';
import { LOCALES } from '~/constants/general';
import {
  MOBILE_NAV_MENU_ITEMS,
  MOBILE_NAV_INFO_ITEMS,
} from './constants';

const authStore = useAuthStore();
const router = useRouter();
const i18n = useI18n();
const { getUserData } = storeToRefs(authStore);
const { setAppLocale } = authStore;
const scssVariables = useScssVariables();
const layoutStore = useLayoutStore();
const { getShowMobileMenu } = storeToRefs(layoutStore);
const localesConvertedToArray = computed(() => {
  return Object.keys(LOCALES).map((localeValue) => ({
    localeValue,
    ...LOCALES[localeValue],
  }));
});
const changeAppLocale = () => {
  const newLocale = i18n.locale.value;
  const sanitizePrefixRegexp = new RegExp(`^/(${i18n.availableLocales.join('|')})(/|$)`);
  const currentPathWithQueries = `${window.location.pathname}${window.location.search}`;
  const pathWithoutLocalePrefix = currentPathWithQueries.replace(sanitizePrefixRegexp, '/');
  const updatedPathWithQueries = newLocale === i18n.defaultLocale
    ? pathWithoutLocalePrefix
    : `/${newLocale}${currentPathWithQueries}`;

  setAppLocale(newLocale);
  router.push(updatedPathWithQueries);
};
</script>

<style lang="scss" scoped>
.j-mobile-menu__item {
  :deep(.v-list-item__content) {
    overflow: initial;
  }
}
</style>
