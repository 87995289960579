<template>
  <v-list
    nav
    class="mt-3 j-mobile-nav"
  >
    <div
      v-for="(navItem, index) of componentProps.menuItemsList"
      :key="`mobile_menu_item_${index}`"
    >
      <!--Nav With Submenu-->
      <v-list-group v-if="navItem.child">
        <template #activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-account-circle"
            class="mb-2 pl-6 pr-4 j-mobile-nav__menu-item"
            active-class="j-mobile-nav__menu-item--active"
            :ripple="false"
          >
            <template #prepend>
              <div class="j-mobile-menu__icon-wrap">
                <v-img
                  :width="22"
                  :src="images[navItem.navIconName]"
                  class="j-mobile-menu__icon"
                  :alt="`icon ${navItem.navIconName}`"
                />
              </div>
            </template>
            <template #title>
              <span class="text-body-1 font-weight-medium ml-4">
                {{ $t(navItem.dictionaryKey) }}
              </span>
            </template>
          </v-list-item>
        </template>
        <v-list-item
          v-for="(subNavItem, subIndex) in navItem.child"
          :key="`mobile_sub_menu_item_${subIndex}`"
          :class="[
            'mb-2',
            'pl-10',
            'j-mobile-nav__submenu-item',
            {
              'j-mobile-nav__submenu-item--active':
                subNavItem.category === gameCategory,
            },
          ]"
          :to="useLocalePrefixForHref(`/games/all/${subNavItem.category?.replaceAll('_', '-').toLowerCase()}`)"
          :ripple="false"
          @click.prevent="changeGameCategory(subNavItem.category as string)"
        >
          <template #prepend>
            <div class="j-mobile-menu__icon-wrap">
              <v-img
                :width="22"
                :src="images[subNavItem.navIconName]"
                class="j-mobile-menu__icon"
                :alt="`icon ${subNavItem.navIconName}`"
              />
            </div>
          </template>
          <template #title>
            <span class="text-body-1 font-weight-medium ml-4">
              {{ $t(subNavItem.dictionaryKey) }}
            </span>
          </template>
        </v-list-item>
      </v-list-group>
      <!--Plain Nav Menu-->
      <v-list-item
        v-else
        :value="$t(navItem.dictionaryKey)"
        :to="useLocalePrefixForHref(navItem.route!)"
        class="mb-2 pl-6 pr-4 j-mobile-nav__menu-item"
        active-class="j-mobile-nav__menu-item--active"
        :active="checkRoutePath(navItem)"
        :ripple="false"
      >
        <template #prepend>
          <div class="j-mobile-menu__icon-wrap">
            <v-img
              :width="22"
              :src="images[navItem.navIconName]"
              class="j-mobile-menu__icon"
              :alt="`icon ${navItem.navIconName}`"
            />
          </div>
        </template>
        <template #title>
          <span class="text-body-1 font-weight-medium ml-4">
            {{ $t(navItem.dictionaryKey) }}
          </span>
        </template>
      </v-list-item>
    </div>
  </v-list>
</template>

<script setup lang="ts">
import { GAME_PROVIDERS } from '~/constants/general';
import type { NavItem } from '~/types/components/General/Header';

const { $eventEmitter } = useNuxtApp();
const images = useAssetsImages();
const componentProps = defineProps({
  menuItemsList: {
    type: Array as PropType<NavItem[]>,
    default: () => [],
  },
});
const route = useRoute();
const router = useRouter();
const gameCategory = ref((route.params.gameCategory as string)?.toUpperCase());

const changeGameCategory = (category: string) => {
  $eventEmitter.emit('change-game-category', category);

  if (!route.path.includes('games/')) {
    const gamesRoute = useLocalePrefixForHref(`/games/${GAME_PROVIDERS.all}/${category}`.toLowerCase());

    router.push({ path: gamesRoute });
  }
};
const checkRoutePath = (navItem: NavItem) =>  route.path.includes(navItem.route?.split('/')[1] as string);

onMounted(() => {
  nextTick(async () => {
    $eventEmitter.on(
      'get-game-category',
      (category: string) => (gameCategory.value = category),
    );
  });
});
onUnmounted(() => {
  $eventEmitter.off('get-game-category');
});
</script>

<style lang="scss" scoped>
.j-mobile-menu__icon-wrap {
  display: grid;
  place-items: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: $j-color-btn-regular;
}

.j-mobile-nav__submenu-item {
  -webkit-padding-start: 48px !important;
  padding-inline-start: 48px !important;
}

.j-mobile-nav__menu-item,
.j-mobile-nav__submenu-item {
  :deep(.v-list-item__overlay) {
    background: transparent;
  }
  &.j-mobile-nav__menu-item--active,
  &.j-mobile-nav__submenu-item--active {
    .j-mobile-menu__icon-wrap {
      background-color: $j-color-primary;
    }
    .j-mobile-menu__icon {
      filter: $j-filter-mob-menu-item-active-icon;
    }
  }
}
</style>
