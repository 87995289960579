export const MOBILE_NAV_MENU_ITEMS = [
  {
    dictionaryKey: 'general_games',
    navIconName: 'mobile_nav_chess',
    child: [
      {
        dictionaryKey: 'general_all_games',
        navIconName: 'mobile_nav_puzzle',
        category: 'ALL',
      },
      {
        dictionaryKey: 'general_slots',
        navIconName: 'mobile_nav_slots',
        category: 'SLOT',
      },
      {
        dictionaryKey: 'general_jackpots',
        navIconName: 'mobile_nav_jackpots',
        category: 'JACKPOT',
      },
      {
        dictionaryKey: 'general_live_games',
        navIconName: 'mobile_nav_live_games',
        category: 'LIVE',
      },
      {
        dictionaryKey: 'general_table_games',
        navIconName: 'mobile_nav_board_games',
        category: 'TABLE_GAMES',
      },
      {
        dictionaryKey: 'general_video_poker',
        navIconName: 'mobile_nav_video_poker',
        category: 'VIDEO_POKER',
      },
      {
        dictionaryKey: 'general_lotteries',
        navIconName: 'mobile_nav_lotteries',
        appendDivider: true,
        category: 'LOTO',
      },
    ],
  },
];

export const MOBILE_NAV_INFO_ITEMS = [
  {
    dictionaryKey: 'general_news',
    route: '/news',
    navIconName: 'mobile_nav_news',
  },
  {
    dictionaryKey: 'general_promo',
    route: '/promo',
    navIconName: 'mobile_nav_promo',
  },
  {
    dictionaryKey: 'general_tournaments',
    route: '/tournaments/new',
    navIconName: 'mobile_nav_tournaments',
  },
];
