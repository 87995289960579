<template>
  <v-app-bar
    :color="scssVariables.jColorBgMain"
    class="j-header-mobile"
    height="82"
    flat
  >
    <v-row no-gutters>
      <v-col
        cols="4"
        class="pl-2"
      >
        <v-btn
          icon
          variant="plain"
          @click.stop="toggleMobileMenu"
        >
          <v-img
            :width="menuControlIconWidth"
            :height="menuControlIconHeight"
            :src="images[menuControlIconName]"
            alt="icon menu"
          />
        </v-btn>
      </v-col>
      <v-col
        cols="4"
        class="d-flex justify-center"
      >
        <nuxt-link
          class="j-link j-link--img"
          :to="useLocalePrefixForHref('/')"
          @click.prevent="useScrollToTop()"
        >
          <v-img
            :width="96"
            :lazy-src="images['logo_jozz']"
            :src="images['logo_jozz']"
            alt="logo"
          />
        </nuxt-link>
      </v-col>
      <v-col
        v-if="getUserData.loggedIn"
        cols="4"
        class="d-flex justify-end align-center"
      >
        <v-btn
          variant="plain"
          :ripple="false"
          class="j-header__logout-btn px-0 ml-7"
          @click="logout"
        >
          <v-img
            :width="18"
            :src="images['logout']"
            alt="icon logout"
          />
        </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useLayoutStore } from '~/stores/layout';
import { useAuthStore } from '~/stores/auth';

const authStore = useAuthStore();
const { logout } = authStore;
const { getUserData } = storeToRefs(authStore);
const layoutStore = useLayoutStore();
const { toggleMobileMenu } = layoutStore;
const scssVariables = useScssVariables();
const images = useAssetsImages();
const { getShowMobileMenu } = storeToRefs(layoutStore);
const menuControlIconName = computed(() => {
  return getShowMobileMenu.value
    ? 'close'
    : 'mobile_menu';
});
const menuControlIconWidth = computed(() => {
  return getShowMobileMenu.value
    ? '24'
    : '20';
});
const menuControlIconHeight = computed(() => {
  return getShowMobileMenu.value
    ? '24'
    : '14';
});
</script>
