<template>
  <v-app-bar
    :color="scssVariables.jColorBgMain"
    class="j-header"
    height="90"
    flat
  >
    <div class="j-container j-container--fw d-flex align-center px-15 mx-auto">
      <div class="j-header__logo mr-9">
        <nuxt-link
          class="j-link j-link--img"
          :to="useLocalePrefixForHref('/')"
          @click.prevent="useScrollToTop()"
        >
          <v-img
            :width="96"
            :lazy-src="images['logo_jozz']"
            :src="images['logo_jozz']"
            alt="logo"
          />
        </nuxt-link>
      </div>
      <div class="j-header__nav ml-9 d-flex">
        <div
          v-for="(navItem, index) of HEADER_NAV_ITEMS"
          :key="index"
          class="j-header__nav-item px-2 py-1 rounded"
        >
          <nuxt-link
            :to="useLocalePrefixForHref(navItem.route)"
            :class="[
              'j-link',
              'j-link--fw-500',
              'j-link--main-page',
              { 'j-link--main-page-active': useLocalePrefixForHref(navItem.route) === route.path && index === 0
                || route.path.includes(navItem.route.split('/')[1]) && index !== 0 },
            ]"
          >
            {{ $t(navItem.dictionaryKey) }}
          </nuxt-link>
        </div>
      </div>
      <v-spacer />
      <div class="j-header__services d-flex align-center">
        <general-logged-user-panel v-if="getUserData.loggedIn" />
        <general-login-buttons-block v-else />
        <v-menu>
          <template #activator="{ props }">
            <v-btn
              :color="scssVariables.jColorBgBlock"
              variant="flat"
              size="large"
              class="ml-6 px-4 text-none text-body-1"
              min-width="auto"
              v-bind="props"
            >
              {{ LOCALES[$i18n.locale].localeNameDesktop }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(locale, index) in i18nLocalesExcludingSelected"
              :key="index"
              @click="changeAppLocale(locale)"
            >
              <v-list-item-title class="text-center">
                <nuxt-link
                  class="j-link j-link--pointer-events-none"
                  :href="getLocaleHref(locale)"
                >
                  {{ LOCALES[locale].localeNameDesktop }}
                </nuxt-link>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          v-if="getUserData.loggedIn"
          variant="plain"
          :ripple="false"
          class="j-header__logout-btn px-0 ml-7"
          @click="logout"
        >
          <v-img
            :width="18"
            alt="logout"
            :src="images['logout']"
          />
        </v-btn>
      </div>
    </div>
  </v-app-bar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { LOCALES } from '~/constants/general';
import { HEADER_NAV_ITEMS } from './constants';

const authStore = useAuthStore();
const {
  setAppLocale,
  logout,
} = authStore;
const { getUserData } = storeToRefs(authStore);
const i18n = useI18n();
const images = useAssetsImages();
const scssVariables = useScssVariables();
const route = useRoute();
const router = useRouter();
const i18nLocalesExcludingSelected = computed(() => {
  return i18n.availableLocales.filter((locale) => locale !== i18n.locale.value);
});
const getLocaleHref = (locale: string) => locale === i18n.defaultLocale
  ? router.currentRoute.value.path.slice(3)
  : `/${locale}${router.currentRoute.value.path}`;
const changeAppLocale = (newLocale: string) => {
  const sanitizePrefixRegexp = new RegExp(`^/(${i18n.availableLocales.join('|')})(/|$)`);
  const currentPathWithQueries = `${window.location.pathname}${window.location.search}`;
  const pathWithoutLocalePrefix = currentPathWithQueries.replace(sanitizePrefixRegexp, '/');
  const updatedPathWithQueries = newLocale === i18n.defaultLocale
    ? pathWithoutLocalePrefix
    : `/${newLocale}${currentPathWithQueries}`;

  i18n.locale.value = newLocale;
  setAppLocale(newLocale);
  router.push(updatedPathWithQueries);
};
</script>

<style lang="scss" scoped>
.j-header__logout-btn {
  min-width: auto;
}
.j-header__nav-item:hover {
  background-color: $j-color-bg-nav-bar;
}
</style>
