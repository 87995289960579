<template>
  <div class="j-logged-user-panel d-inline-flex justify-center align-center flex-column-reverse flex-lg-row">
    <div class="d-flex align-center mt-4 mt-lg-0 w-100 me-lg-auto">
      <nuxt-link
        class="j-link"
        :to="useLocalePrefixForHref('/cabinet/balance#bonuses-and-actions')"
        @click.prevent="useScrollToAnchor(useLocalePrefixForHref('/cabinet/balance#bonuses-and-actions'))"
      >
        <v-btn
          :variant="bonusBtnVariant"
          :ripple="false"
          size="large"
        >
          <v-icon size="large">
            mdi-gift-outline
          </v-icon>
          <div
            :class="[
              'j-logged-user-panel__badge',
              'd-flex rounded-circle',
              'justify-center align-content-center',
              'flex-wrap',
              'ml-3',
              'ml-lg-0',
            ]"
          >
            <general-text
              :text="getUserData.bonusesCount"
              class="j-text--fs-12 j-text--white d-block"
            />
          </div>
        </v-btn>
      </nuxt-link>
      <nuxt-link
        :to="useLocalePrefixForHref('/cabinet/balance')"
        :class="[
          'j-link',
          'j-link--bg-primary',
          'j-link--h-44px',
          'rounded',
          'px-4',
          'ml-2',
          'ml-lg-3',
          'd-flex',
          'justify-center',
          'flex-column',
          'flex-grow-1',
        ]"
      >
        <general-text
          :text="balanceText"
          class="j-text--fs-12 j-text--line-height-14 j-text--color-balance d-block"
        />
        <general-text
          :text="activeBalanceValue"
          class="j-text--white j-text--fs-16 j-text--line-height-19 font-weight-medium"
        />
      </nuxt-link>
    </div>
    <div
      :class="[
        'j-logged-user-panel__block',
        'text-body-1',
        'ml-lg-4',
        'rounded',
        'd-flex',
        'align-center',
        'justify-center',
        { 'w-100': Boolean(isMobileMode) },
      ]"
    >
      <v-btn
        variant="text"
        :class="[
          'j-btn',
          'text-none',
          'text-body-1',
          'pl-9',
          'pl-lg-5',
          'pr-0',
          'flex-grow-1',
          'h-100',
          'align-content-center',
          'pr-2',
          'rounded-e-0',
          { 'j-btn--no-hover j-btn--no-active': Boolean(isMobileMode) },
        ]"
        @click="activateCash"
      >
        <div class="d-flex align-center justify-end">
          <v-img
            :width="16"
            :max-width="16"
            :height="16"
            alt="icon auth-shield"
            :src="images['auth_shield']"
          />
          <general-text
            dictionary-key="general_cashier"
            class="j-text--white j-text--fs-16 font-weight-medium ml-2"
          />
        </div>
      </v-btn>
      <span class="j-logged-user-panel__main-btn-vertical-line" />
      <nuxt-link
        :to="useLocalePrefixForHref('/cabinet')"
        class="j-link j-link--logged-user-panel-hover pr-9 pr-lg-5
          flex-grow-1 h-100 align-content-center pl-2 rounded-bs-0 rounded-e"
      >
        <general-text
          :text="getUserData?.screenName || 'user'"
          class="j-text--white j-text--fs-16 j-text--mw-110 font-weight-medium text-truncate"
        />
      </nuxt-link>
      <v-img
        v-if="getUserData.needDocumentsVerification"
        :width="24"
        :src="images['need-verification']"
        alt="need-verification"
        class="j-logged-user-panel__verification-icon"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { useCashStore } from '~/stores/cash';
import { BALANCE_TYPES } from '~/constants/general';

const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const { t } = useI18n();
const images = useAssetsImages();
const cashStore = useCashStore();
const { activateCash } = cashStore;
const authStore = useAuthStore();
const {
  getUserData,
  getUserActiveBalance,
} = storeToRefs(authStore);
const activeBalanceValue = computed(
  () => `${getUserActiveBalance.value?.amount} ${getUserActiveBalance.value?.currency}`,
);
const balanceText = computed(() => getUserActiveBalance.value?.type === BALANCE_TYPES.crypto
  ? `${t('general_balance')} ≈ ${getUserActiveBalance.value.amountInUsd ?? '0.00'} USD`
  : `${t('general_balance')}:`,
);
const bonusBtnVariant = computed(() => isMobileMode?.value
  ? 'flat'
  : 'plain',
);
</script>

<style lang="scss" scoped>
.j-logged-user-panel {
  width: 100%;
  @media (min-width: 1280px) {
    width: auto;
  }
}

.j-logged-user-panel__badge {
  background-color: $j-color-bg-block-light;
  border: 1px solid $j-color-green;
  width: 24px;
  height: 24px;
  @media (min-width: 1280px) {
    position: absolute;
    right: 6px;
    top: -10px;
  }
}

.j-logged-user-panel__verification-icon {
  position: absolute;
  top: -8px;
  right: -8px;
}

.j-logged-user-panel__block {
  position: relative;
  background: $j-gradient-btn-main;
  height: 44px;
  border: 1px solid transparent;
}

.j-logged-user-panel__main-btn-vertical-line {
  position: relative;
  &:before {
    content: "";
    width: 1px;
    height: 42px;
    position: absolute;
    left: 0px;
    top: -21px;
    background-color: $j-color-btn-main-divider;
  }
}
</style>
