export const HEADER_NAV_ITEMS = [
  {
    dictionaryKey: 'general_games',
    route: '/',
  },
  {
    dictionaryKey: 'general_news',
    route: '/news',
  },
  {
    dictionaryKey: 'general_promo',
    route: '/promo',
  },
  {
    dictionaryKey: 'general_tournaments',
    route: '/tournaments/new',
  },
];
